import React from 'react'
import Image from 'gatsby-image'
import { Link } from 'gatsby'
import { FaSearch } from 'react-icons/fa'

const WorkSample = ({ work }) => {
	const { name, slug, mainImage } = work
	// url, tech, images, description,
	return (
		<>
			<Link className="work-sample-item" to={`work/${slug}`}>
				<div className="work-sample-content">
					<div className="text-col">
						<h3>Client:</h3>
						<h2>{name}</h2>
					</div>
					<div className="bg-image-container">
						<Image fluid={mainImage.fluid} />
					</div>
				</div>
				<div className="spyglass">
					<FaSearch />
				</div>
			</Link>
		</>
	)
}

export default WorkSample
