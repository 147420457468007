import React from 'react'
import LayoutMain from '../components/layouts/LayoutMain'
import WorkSamples from '../components/WorkSamples'
import { graphql } from 'gatsby'

const index = ({ data }) => {
	return (
		<LayoutMain isIndex={true}>
			<WorkSamples allWork={data.work.edges} />
		</LayoutMain>
	)
}

export default index

export const getWorkSamples = graphql`
	query getAllWorkBasic {
		work: allContentfulWorkExamples {
			edges {
				node {
					mainImage {
						fluid {
							...GatsbyContentfulFluid
						}
					}
					name
					slug
					id: contentful_id
					tech: technologies
				}
			}
		}
	}
`
