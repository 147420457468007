import React from 'react'
import WorkSample from './WorkSample'

const WorkSamples = ({ allWork }) => {
	return (
		<div className="work-samples-container ">
			{allWork.map(({ node }) => {
				return <WorkSample key={node.id} work={node} />
			})}
		</div>
	)
}

export default WorkSamples
